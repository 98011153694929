import { CampaignWizard, NewBreadcrumb, SpinLoader } from "@/components";
import IOSPreview from "components/Device/IOSPreview/IOSPreview";
import PrimaryButton from "components/Form/Buttons/PrimaryButton/PrimaryButton";
import SecondaryButton from "components/Form/Buttons/SecondaryButton/SecondaryButton";
import { get, isEmpty, pickBy } from "lodash";
import { getDefaultTimezoneFromSuit } from "modules/helpers";
import { createInitConfig, handleChangeConfig } from "modules/push";
import { hasAccessToProduct, hasProduct } from "modules/user";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import Notifications from "react-notification-system-redux";
import { I18n } from "react-redux-i18n";
import { getSuitCustomerSegments } from "reducers/CustomerSegment";
import { getSuitLandingPages } from "reducers/MicroPages";
import { sendPush } from "reducers/config";
import { duplicateCampaign } from "reducers/push";
import { getSuitSmartlinks } from "reducers/smartlink";
import { getSuitTemplates } from "reducers/template";
import { getAppBackendConfig } from "reducers/user";
import { getFormValues, initialize, isValid } from "redux-form";
import { RoutedComponent, connect } from "routes/routedComponent";
import productsNames from "../../../../consts/productsNames";
import { MOBILE_APP, RETAIL_GAIN } from "../../../../consts/suitTypes";
import { Toast } from "../../../../modules/toast";
import { identifySuitType } from "../../../../modules/user";
import { getSegments } from "../../RetailGainSegments/API/RetailGainSegments.api";
import classes from "./CreateWebPush.scss";
import { PushMessageStep, PushSendStep } from "./components";

export const frequencyOptions = [
  {
    label: I18n.t("day"),
    value: "day",
  },
  {
    label: I18n.t("week"),
    value: "week",
  },
];

export const repeatOptions = [
  { label: I18n.t("oneTime"), value: "oneTime" },
  { label: I18n.t("Repeat"), value: "repeat" },
];

export const sendOptions = [
  {
    label: I18n.t("Sendnow"),
    value: "sendNow",
  },
  {
    label: I18n.t("Sendlater"),
    value: "sendLater",
  },
];

export const URL_TYPES = {
  NO_URL: "noUrl",
  URL: "url",
  SMART_LINK: "smartLink",
  MICRO_PAGE: "microPage",
  DEEP_LINK: "deepLink",
};

export const TARGET_TYPES = {
  NO_TARGET: "noTarget",
  SEGMENTS: "segments",
  USER_ID: "userId",
};

export const urlOptions = [
  {
    value: URL_TYPES.NO_URL,
    label: I18n.t("noURL"),
  },
  { value: URL_TYPES.URL, label: I18n.t("URL") },
  {
    value: URL_TYPES.DEEP_LINK,
    label: I18n.t("appDeepLink"),
  },
  {
    value: URL_TYPES.SMART_LINK,
    label: I18n.t("smartLink"),
  },
  {
    value: URL_TYPES.MICRO_PAGE,
    label: I18n.t("microPage"),
  },
];

export const targetOptions = [
  {
    value: TARGET_TYPES.NO_TARGET,
    label: I18n.t("allusersAndDevices"),
  },
  {
    value: TARGET_TYPES.SEGMENTS,
    label: I18n.t("Segments"),
  },
  {
    value: TARGET_TYPES.USER_ID,
    label: I18n.t("UserId"),
  },
];

export const messageDirectionOptions = [
  { label: I18n.t("ltr"), value: "ltr" },
  { label: I18n.t("rtl"), value: "rtl" },
];

const sequence = ["message", "send"];

let steps = [
  {
    label: I18n.t("createWepPushPage_createLabel"),
    width: 25,
    value: "Create Message",
  },
  {
    label: I18n.t("createWepPushPage_deliveryLabel"),
    width: 25,
    value: "Delivery",
  },
];

class CreatePushContainer extends RoutedComponent {
  constructor(props, context) {
    super(props, context);
    this.state = {
      data: {},
      selectedTemplate: {
        label: I18n.t("SelectTemplate"),
        value: "noTemplate",
      },
      urlValue: { label: "", value: "" },
      currStepIdx: 1,
      currentStep: sequence[0],
      advancedOpen: {},
      type: "webPush",
      urlType: urlOptions[0],
      targetType: {
        value: "noTarget",
        label: I18n.t("allusersAndDevices"),
      },
      segmentsVal: "",
      selectedUrl: "",
      selectedDevice: "",
      sendNow: true,
      repeat: false,
      time: {
        timezone: "",
        start: "",
        end: "",
        frequency: "day",
      },

      segmentOptions: [],
    };

    let subDomain = this.props.params.subdomain;

    !hasAccessToProduct(
      this.props.user.activeSuitId,
      productsNames.webPushNotification,
      this.props
    ) && this.context.router.push(`/app/` + subDomain);
  }

  async componentDidMount() {
    const {
      user: { activeSuitId, suits, suit, appbackend },
      getSuitLandingPages,
      getSuitSmartlinks,
      getSuitTemplates,
      getSuitCustomerSegments,
      getAppBackendConfig,
      push,
    } = this.props;

    const currentSuit = suit || suits.find((suit) => suit.id === activeSuitId);

    const timezone = getDefaultTimezoneFromSuit(currentSuit);
    const suitType = identifySuitType(currentSuit?.plan_summary?.offers_values);
    const isMobileApp = suitType === MOBILE_APP;
    const isRetailGain = suitType === RETAIL_GAIN;

    console.log("isRtailgain?  " + isRetailGain);
    let appBackend = appbackend;

    if (!appBackend && isMobileApp) {
      const response = await getAppBackendConfig(
        currentSuit.subdomain,
        currentSuit.appbackend_userId
      );

      appBackend = response.value.body;
    }

    const promises = [];

    isMobileApp
      ? promises.push(
          getSuitCustomerSegments(
            appBackend.serverURL + "fe",
            appBackend.appId,
            appBackend.masterKey
          )
        )
      : promises.push(getSegments(currentSuit.subdomain));
    hasProduct(activeSuitId, productsNames.mobileLandingPages) &&
      promises.push(getSuitLandingPages(activeSuitId));
    hasProduct(activeSuitId, productsNames.smartDeepLink) &&
      promises.push(getSuitSmartlinks(activeSuitId));
    promises.push(getSuitTemplates(activeSuitId, "webPush"));

    let segmentOptions;
    try {
      const response = await Promise.allSettled(promises);

      const segmentsResponse = response[0];
      if (isRetailGain) {
        segmentOptions = segmentsResponse.value.results.map((segment) => ({
          label: segment.name,
          value: segment.objectId,
        }));
      } else if (isMobileApp) {
        segmentOptions = segmentsResponse.value.value.body.result.data.map(
          (segment) => ({
            label: segment.name,
            value: segment.objectId,
          })
        );
      }
    } catch (err) {
      console.error(err);
      Toast.error("Something went wrong. Please try again later");
    }

    this.setState({
      data: createInitConfig(`webPush`, activeSuitId, currentSuit.api_key),
      type: "webPush",
      time: {
        timezone: timezone || "",
        start: "",
        end: "",
        frequency: "day",
      },
      segmentOptions,
      urlType: push?.duplicate?.creation_payload?.webPush?.url
        ? urlOptions[1]
        : urlOptions[0],
    });

    const pushMessageStepInitialValues = {
      action: {
        webPush: {
          dir: messageDirectionOptions[1], // rtl
          requireInteraction: true,
          actions: [],
          silent: false,
        },
      },
    };

    if (push.duplicate) {
      const { creation_payload } = push.duplicate;

      pushMessageStepInitialValues.action.webPush.campaign_name =
        creation_payload.campaign_name + " copy";
      pushMessageStepInitialValues.action.webPush.message =
        creation_payload.webPush.message;
      pushMessageStepInitialValues.action.webPush.url =
        creation_payload.webPush.url;

      // TODO: add new fields
    }

    this.props.initialize("pushMessageStep", pushMessageStepInitialValues);

    // add the initial values to data object
    this.handleChange("action", pushMessageStepInitialValues.action);
  }

  componentWillUnmount() {
    this.props.duplicateCampaign(null);
  }

  static contextTypes = {
    router: PropTypes.object.isRequired,
  };

  handleChange = (type, value) => {
    this.setState((prevState) => ({
      data: handleChangeConfig(type, value, prevState.data),
    }));
  };

  handleSelectUrl = (newValue) => {
    this.handleChange("action.webPush.url", undefined);
    this.setState({
      urlType: newValue,
      urlValue: { label: "", value: "" },
    });
  };

  handleURLValueChange = (label, value) => {
    const { type } = this.state;
    this.setState({ urlValue: { label, value } });
    this.handleChange(`action.${type}.url`, value);
  };

  handleSelectTarget = (targetType) => {
    this.setState({ targetType });
    if (targetType.value === TARGET_TYPES.NO_TARGET) {
      this.handleChange(`action.webPush.userId`, undefined);
    } else if (targetType.value === TARGET_TYPES.USER_ID) {
      this.handleChange(
        `action.webPush.userId`,
        get(
          this.props.form,
          ["pushMessageStep", "values", "action_targetTypeUserId"],
          ""
        )
      );
    }
  };

  toggleSendNow = () => {
    this.setState(
      (prevState) => ({
        sendNow: !prevState.sendNow,
      }),
      this.formatTime
    );
  };

  toggleRepeat = () => {
    this.setState(
      (prevState) => ({
        repeat: !prevState.repeat,
      }),
      this.formatTime
    );
  };

  handleTimeZoneChange = (newValue) => {
    this.setState(
      (prevState) => ({
        time: { ...prevState.time, timezone: newValue },
      }),
      this.formatTime
    );
  };

  handleStartTimeChange = (moment) => {
    this.setState(
      (prevState) => ({
        time: { ...prevState.time, start: moment },
      }),
      this.formatTime
    );
  };

  handleEndTimeChange = (moment) => {
    this.setState(
      (prevState) => ({
        time: { ...prevState.time, end: moment },
      }),
      this.formatTime
    );
  };

  handleFrequencyChange = (frequency) => {
    this.setState(
      (prevState) => ({
        time: { ...prevState.time, frequency },
      }),
      this.formatTime
    );
  };

  formatTime = () => {
    const {
      repeat,
      sendNow,
      time: { start, end, frequency, timezone },
    } = this.state;

    const startFormat = moment(start).format("YYYY/MM/DD,HH:mm");
    const endFormat = moment(end).format("YYYY/MM/DD,HH:mm");

    if (sendNow) {
      this.handleChange("repeat", undefined);
    } else {
      if (repeat) {
        if (start && end && frequency && timezone?.offset) {
          this.handleChange("repeat", {
            frequency: frequency,
            frequency_start: startFormat + timezone?.offset,
            period_start: startFormat + timezone?.offset,
            period_end: endFormat + timezone?.offset,
          });
        }
      } else {
        if (start && timezone?.offset) {
          this.handleChange("repeat", {
            frequency: "minute",
            frequency_start: startFormat + timezone?.offset,
            period_start: startFormat + timezone?.offset,
            period_end: startFormat + timezone?.offset,
          });
        }
      }
    }
  };

  handleSubmit = () => {
    const {
      sendPush,
      routeParams: { subdomain },
      pushMessageFormValues,
    } = this.props;

    const { data } = this.state;
    data.action.webPush?.url?.trim();

    data.action.webPush.actions?.length === 0 &&
      delete data.action.webPush.actions;

    data.action.webPush.dir = data.action.webPush.dir.value;

    if (
      pushMessageFormValues.action?.webPush?.variables &&
      !isEmpty(pushMessageFormValues.action?.webPush?.variables)
    ) {
      Object.keys(pushMessageFormValues.action?.webPush?.variables).forEach(
        (key) => {
          data.action.webPush[key] =
            pushMessageFormValues.action?.webPush?.variables[key];
        }
      );
      data.action.webPush.template_name = this.state.selectedTemplate.label;

      // delete the variables from the action
      delete data.action.webPush.variables;
    }

    sendPush(pickBy(data))
      .then(() => {
        Toast.success(I18n.t("createWepPushPage_sentMsg"));
      })
      .then(() => {
        this.context.router.push(`/app/${subdomain}/campaignssummary`);
      });
  };

  changeCurrentStep = (label) => {
    const newIdx = steps.map(({ label }) => label).indexOf(label) + 1;
    const currIdx = this.state.currStepIdx;
    if (currIdx > newIdx) {
      // To go back
      this.setState({ currStepIdx: newIdx });
    } else if (newIdx - currIdx === 1) {
      // at max to go one step forward
      if (this.canStepChange()) {
        this.setState({ currStepIdx: newIdx });
      }
    }
  };

  canStepChange() {
    const { form, pushMessageFormValid, pushSendFormValid } = this.props;

    const {
      currStepIdx,
      sendNow,
      repeat,
      time: { start, end, timezone },
    } = this.state;
    const curStep = steps[currStepIdx - 1];
    const stepValue = curStep.value;

    if (form) {
      if (stepValue === "Create Message") {
        return pushMessageFormValid;
      }
      if (stepValue === "Delivery") {
        if (sendNow) {
          return pushSendFormValid;
        } else {
          if (repeat) {
            return start && end && timezone && pushSendFormValid;
          } else {
            return start && timezone && pushSendFormValid;
          }
        }
      }
      return false;
    }
  }

  renderPreview = () => {
    const { data } = this.state;

    return (
      <IOSPreview
        type="appPush"
        data={get(data, ["action", "webPush", "message"], "")}
      />
    );
  };

  handleTemplateSelect = (selectedTemplate) => {
    this.setState({ selectedTemplate });
  };

  renderStepContent = () => {
    const {
      urlType,
      targetType,
      currStepIdx,
      sendNow,
      repeat,
      time,
      segmentOptions,
    } = this.state;

    const step = steps[currStepIdx - 1];
    const stepValue = step.value;

    return (
      <div className={classes["apppush-content"]}>
        <div>
          {stepValue === "Create Message" && (
            <PushMessageStep
              selectedTemplate={this.state.selectedTemplate}
              handleTemplateSelect={this.handleTemplateSelect}
              TemplatesObjects={this.props.templates}
              urlValue={this.state.urlValue}
              handleURLValueChange={this.handleURLValueChange}
              handleChange={this.handleChange}
              urlType={urlType}
              handleSelectUrl={this.handleSelectUrl}
            />
          )}

          {stepValue === "Delivery" && (
            <PushSendStep
              handleChange={this.handleChange}
              sendNow={sendNow}
              toggleSendNow={this.toggleSendNow}
              repeat={repeat}
              toggleRepeat={this.toggleRepeat}
              handleTimeZoneChange={this.handleTimeZoneChange}
              handleStartTimeChange={this.handleStartTimeChange}
              handleEndTimeChange={this.handleEndTimeChange}
              handleFrequencyChange={this.handleFrequencyChange}
              time={time}
              targetType={targetType}
              handleSelectTarget={this.handleSelectTarget}
              segmentOptions={segmentOptions}
            />
          )}
        </div>

        <div>{this.renderPreview()}</div>
      </div>
    );
  };

  render() {
    const {
      params,
      config: { isPending },
    } = this.props;

    const { currStepIdx } = this.state;

    const shouldRenderBackButton = currStepIdx !== 1;

    return (
      <div className={classes.createAppPush}>
        <div className={classes.header}>
          <NewBreadcrumb fullPath={["campaigns", "webPush", "new"]} />
        </div>

        {isPending && <SpinLoader />}

        <div className={classes.content}>
          <CampaignWizard
            steps={steps}
            currentStep={currStepIdx}
            changeCurrentStep={this.changeCurrentStep}
          >
            {this.renderStepContent()}
          </CampaignWizard>

          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "2rem",
              alignItems: "center",
              gap: "16px",
              marginBottom: "24px",
            }}
          >
            {shouldRenderBackButton && (
              <SecondaryButton
                style={{ marginRight: "1rem" }}
                value={I18n.t("backBtn")}
                onClick={() =>
                  this.changeCurrentStep(steps[currStepIdx - 2].label)
                }
              />
            )}

            <SecondaryButton
              style={{ marginRight: "1rem" }}
              value={I18n.t("cancelBtn")}
              onClick={() =>
                this.context.router.push(
                  `/app/${params.subdomain}/newcampaigns`
                )
              }
            />

            <PrimaryButton
              value={
                currStepIdx === steps.length
                  ? `${I18n.t("submitBtn")}`
                  : `${I18n.t("nextBtn")}`
              }
              disabled={!this.canStepChange()}
              onClick={() => {
                currStepIdx === steps.length
                  ? this.handleSubmit()
                  : this.changeCurrentStep(steps[currStepIdx].label);
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  (store) => ({
    user: store.user,
    templates: store.template.TemplatesObjects,
    config: store.config,
    form: store.form,
    push: store.push,
    segments: store.CustomerSegment.SegmentsObj,
    pushMessageFormValid: isValid("pushMessageStep")(store),
    pushSendFormValid: isValid("pushSendStep")(store),
    pushMessageFormValues: getFormValues("pushMessageStep")(store),
  }),
  {
    getSuitLandingPages,
    getSuitSmartlinks,
    getSuitTemplates,
    sendPush,
    getSuitCustomerSegments,
    showNotification: Notifications.show,
    getAppBackendConfig,
    duplicateCampaign,
    initialize,
  }
)(CreatePushContainer);
