import axios from "axios";
import { config } from "../consts/api";

const uploadImageToServer = async (file, suitId, appApiKey) => {
  const formData = new FormData();
  formData.append("photo", file);
  const response = await axios.post(
    `${config.API_HOST}/${suitId}/upload`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        appApiKey,
      },
    }
  );

  const url = `https://${response.data}`;
  return url;
};

const uploadFileToServer = async (file, suitId, appApiKey) => {
  const formData = new FormData();
  formData.append("file", file);
  const response = await axios.post(
    `${config.API_HOST}/${suitId}/upload`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        appApiKey,
      },
    }
  );

  const url = `https://${response.data}`;
  return url;
};

export { uploadImageToServer, uploadFileToServer };
