import { DATE_TYPE, OBJECT, STRING, NUMBER } from "consts/TableColumnTypes";
import { config } from "consts/api";
import _ from "lodash";
import moment from "moment";
import axiosInstance from "./axios";

const { mobileMarketingParseUrl } = config;

export const getSegments = async (subdomain, pageSize = 9999) => {
  try {
    const response = await axiosInstance.post(
      `${mobileMarketingParseUrl}/functions/getAllSegments`,
      { subdomain, pageSize }
    );

    return response.data;
  } catch (err) {
    console.error(err.message);
  }
};

export const deleteSegment = async (subdomain, segmentId) => {
  try {
    const response = await axiosInstance.delete(
      `${mobileMarketingParseUrl}/classes/Segments${subdomain}/${segmentId}`
    );

    return response.data;
  } catch (err) {
    console.error(err.message);
  }
};

export const prepareSegmentsTableData = (segments) => {
  const dateFormat = "DD MMM YY HH:mm:ss";

  const labels = [
    {
      name: "Name",
      has_sort: false,
      type: STRING,
      selected: true,
      varName: "name",
      defaultValue: "",
    },
    {
      name: "Criteria",
      has_sort: false,
      type: STRING,
      selected: true,
      varName: "criteria",
      defaultValue: "",
    },
    {
      name: "Size",
      has_sort: false,
      type: NUMBER,
      selected: true,
      varName: "size",
      defaultValue: "",
    },
    {
      name: "Created At",
      has_sort: false,
      type: DATE_TYPE,
      selected: true,
      varName: "createdAt",
      defaultValue: "",
    },
    {
      name: "Updated At",
      has_sort: false,
      type: DATE_TYPE,
      selected: true,
      varName: "updatedAt",
      defaultValue: "",
    },
  ];

  const rows = segments.map((row) => {
    return {
      checked: false,
      actionsMenu: false,
      id: row.objectId,
      values: labels.map((label) => {
        if (label.type === DATE_TYPE) {
          return moment(
            moment.utc(_.get(row, label.varName, label.defaultValue))
          )
            .local()
            .format(dateFormat);
        } else if (label.type === OBJECT) {
          return JSON.stringify(_.get(row, label.varName, label.defaultValue));
        }
        return _.get(row, label.varName, label.defaultValue);
      }),
    };
  });

  return { labels, rows, actions: true };
};
