import {
  CURRENCY,
  DATE_TYPE,
  NUMBER,
  OBJECT,
  STRING,
} from "consts/TableColumnTypes";
import _ from "lodash";
import moment from "moment";
import { RESET_ERRORS } from "reducers/user";
import UserScreen from "../api/UserScreen";

const GET_USER_SCREEN = "GET_USER_SCREEN";
const ADD_USERPROFILE = "ADD_USERPROFILE";
const GET_MOBILE_ATTRIBUTES = "GET_MOBILE_ATTRIBUTES";
const USERS_SCREEN_CHECK_DATA_TABLE_ROW = "USERS_SCREEN_CHECK_DATA_TABLE_ROW";
const USERS_SCREEN_ON_COLUMN_TOGGLE = "USERS_SCREEN_ON_COLUMN_TOGGLE";
const USERS_SCREEN_CHECK_ALL_DATA_TABLE_ROWS =
  "USERS_SCREEN_CHECK_ALL_DATA_TABLE_ROWS";
const USERS_SCREEN_TOGGLE_ROW_ACTION_MENU =
  "USERS_SCREEN_TOGGLE_ROW_ACTION_MENU";
const USERS_SCREEN_CLEAR_ACTION_MENU = "USERS_SCREEN_CLEAR_ACTION_MENU";
const USERS_SCREEN_ON_RESET_COLUMNS = "USERS_SCREEN_ON_RESET_COLUMNS";
const SORT_USERS_SCREEN = "SORT_USERS_SCREEN";
const GET_USER_SCREEN_COUNT = "GET_USER_SCREEN_COUNT";
const SET_USER_SCREEN_META_DATA = "SET_USER_SCREEN_META_DATA";
const GET_USER_SCREEN_BY_FILTER_COUNT = "GET_USER_SCREEN_BY_FILTER_COUNT";
const GET_USER_SCREEN_BY_FILTER = "GET_USER_SCREEN_BY_FILTER";

//  ------------------------------------
// Actions
// ------------------------------------

export function setMetaData(data) {
  return {
    type: SET_USER_SCREEN_META_DATA,
    payload: data,
  };
}

export function getAppUsers(subDomain, skip, limit, count) {
  if (count) {
    return {
      type: GET_USER_SCREEN_COUNT,
      payload: UserScreen.getAppUsers(subDomain, skip, limit, count),
    };
  } else {
    return {
      type: GET_USER_SCREEN,
      payload: UserScreen.getAppUsers(subDomain, skip, limit, null),
    };
  }
}

export function getAppUsersByFilter(subdomain, skip, limit, match) {
  return {
    type: GET_USER_SCREEN_BY_FILTER,
    payload: UserScreen.getAppUsersByFilter(subdomain, skip, limit, match),
  };
}

export function createUserProfile(
  userId,
  subdomain,
  email,
  name,
  phone,
  activeSuitId
) {
  return {
    type: ADD_USERPROFILE,
    payload: UserScreen.createUserProfile(
      userId,
      subdomain,
      email,
      name,
      phone,
      activeSuitId
    ),
  };
}

// Data table reducer functions
export function checkDataTableRow(idx) {
  return {
    type: USERS_SCREEN_CHECK_DATA_TABLE_ROW,
    payload: idx,
  };
}

export function checkAllTableRows(newChecked) {
  return {
    type: USERS_SCREEN_CHECK_ALL_DATA_TABLE_ROWS,
    payload: newChecked,
  };
}

export function onColumnToggle(idx) {
  return {
    type: USERS_SCREEN_ON_COLUMN_TOGGLE,
    payload: idx,
  };
}

export function toggleRowActionMenu(idx) {
  return {
    type: USERS_SCREEN_TOGGLE_ROW_ACTION_MENU,
    payload: idx,
  };
}

export function clearActionMenu(idx) {
  return {
    type: USERS_SCREEN_CLEAR_ACTION_MENU,
    payload: idx,
  };
}

export function onResetColumns() {
  return {
    type: USERS_SCREEN_ON_RESET_COLUMNS,
  };
}

export const sortTable = (name, type) => async (dispatch) => {
  dispatch({
    type: SORT_USERS_SCREEN,
    payload: {
      type,
      name: name.split("_1")[0],
      descending: name.includes("_1"),
    },
  });
};

// ------------------------------------
// Initial State
// ------------------------------------

const initialState = {
  isPending: false,
  mobileAttributes: [],
  tableData: null,
  users: [],
  usersCount: 0,

  // cash
  page: 1,
  filterMatch: null,
  filterConditions: null,
  filterRelation: null,
  filterConditionsTranslations: null,
};

// ------------------------------------
// Reducer
// ------------------------------------

export default function UserScreenReducer(state = initialState, action) {
  switch (action.type) {
    case RESET_ERRORS:
      return {
        ...state,
        error: false,
      };
    case `${GET_USER_SCREEN}_PENDING`:
    case `${GET_USER_SCREEN_COUNT}_PENDING`:
    case `${ADD_USERPROFILE}_PENDING`:
    case `${GET_MOBILE_ATTRIBUTES}_PENDING`:
    case `${GET_USER_SCREEN_BY_FILTER_COUNT}_PENDING`:
    case `${GET_USER_SCREEN_BY_FILTER}_PENDING`:
      return {
        ...state,
        isPending: true,
      };
    case `${GET_USER_SCREEN}_REJECTED`:
    case `${GET_USER_SCREEN_COUNT}_REJECTED`:
    case `${ADD_USERPROFILE}_REJECTED`:
    case `${GET_MOBILE_ATTRIBUTES}_REJECTED`:
    case `${GET_USER_SCREEN_BY_FILTER_COUNT}_REJECTED`:
    case `${GET_USER_SCREEN_BY_FILTER}_REJECTED`:
      return {
        ...state,
        isPending: false,
      };
    case `${GET_USER_SCREEN}_FULFILLED`:
      return {
        ...state,
        isPending: false,
        users: action.payload.body.results,
        tableData: prepareUserScreenTableDate(action.payload.body.results),
      };
    case `${GET_USER_SCREEN_BY_FILTER_COUNT}_FULFILLED`:
      return {
        ...state,
        isPending: false,
        usersCount:
          action.payload.body.results.length > 0
            ? action.payload.body.results[0].count
            : 0,
      };
    case `${GET_USER_SCREEN_BY_FILTER}_FULFILLED`:
      return {
        ...state,
        isPending: false,
        users: action.payload.body.result.data.users,
        tableData: prepareUserScreenTableDate(
          action.payload.body.result.data.users
        ),
      };
    case `${GET_USER_SCREEN_COUNT}_FULFILLED`:
      return {
        ...state,
        isPending: false,
        usersCount: action.payload.body.count,
      };
    case `${ADD_USERPROFILE}_FULFILLED`:
      return {
        ...state,
        isPending: false,
      };
    case `${GET_MOBILE_ATTRIBUTES}_FULFILLED`:
      return {
        ...state,
        isPending: false,
        mobileAttributes: action.payload.body.mobile_attribution,
      };

    // data table
    case USERS_SCREEN_CHECK_DATA_TABLE_ROW:
      return {
        ...state,
        tableData: {
          ...state.tableData,
          rows: state.tableData.rows.map((row, rowIdx) => {
            return rowIdx === action.payload
              ? { ...row, checked: !row.checked }
              : row;
          }),
        },
      };
    case USERS_SCREEN_CHECK_ALL_DATA_TABLE_ROWS:
      return {
        ...state,
        tableData: {
          ...state.tableData,
          rows: state.tableData.rows.map((row) => ({
            ...row,
            checked: action.payload,
          })),
        },
      };
    case USERS_SCREEN_ON_COLUMN_TOGGLE:
      return {
        ...state,
        tableData: {
          ...state.tableData,
          labels: state.tableData.labels.map((label, idx) => {
            return idx === action.payload
              ? {
                  ...label,
                  selected: !label.selected,
                }
              : label;
          }),
        },
      };
    case USERS_SCREEN_TOGGLE_ROW_ACTION_MENU:
      return {
        ...state,
        tableData: {
          ...state.tableData,
          rows: state.tableData.rows.map((row, rowIdx) => {
            return rowIdx === action.payload
              ? { ...row, actionsMenu: !row.actionsMenu }
              : row;
          }),
        },
      };
    case USERS_SCREEN_CLEAR_ACTION_MENU:
      return {
        ...state,
        tableData: {
          ...state.tableData,
          rows: state.tableData.rows.map((row, idx) => {
            return idx === action.payload
              ? { ...row, actionsMenu: false }
              : row;
          }),
        },
      };
    case USERS_SCREEN_ON_RESET_COLUMNS:
      return {
        ...state,
        tableData: {
          ...state.tableData,
          labels: state.tableData.labels.map((label) => ({
            ...label,
            selected: true,
          })),
        },
      };
    case SORT_USERS_SCREEN: {
      const indexOfKey = state.tableData.labels
        .map((label) => label.name)
        .indexOf(action.payload.name);

      let tableRows = null;

      switch (action.payload.type) {
        case NUMBER:
          tableRows = _.sortBy(
            state.tableData.rows,
            (row) => row.values[indexOfKey]
          );
          break;
        case STRING:
          tableRows = _.sortBy(state.tableData.rows, (row) =>
            row.values[indexOfKey].toLowerCase()
          );
          break;
        case CURRENCY:
          tableRows = _.sortBy(state.tableData.rows, (row) =>
            parseFloat(row.values[indexOfKey].split(" ")[0])
          );
          break;
        case DATE_TYPE:
          tableRows = _.sortBy(state.tableData.rows, (row) =>
            new Date(row.values[indexOfKey]).getTime()
          );
          break;
      }
      if (action.payload.descending) {
        _.reverse(tableRows);
      }
      return {
        ...state,
        tableData: {
          ...state.tableData,
          rows: tableRows,
        },
      };
    }
    case SET_USER_SCREEN_META_DATA:
      return {
        ...state,
        page: action.payload.page,
        filterMatch: action.payload.filterMatch
          ? action.payload.filterMatch
          : null,
        filterConditions: action.payload.filterConditions
          ? action.payload.filterConditions
          : null,
        filterRelation: action.payload.filterRelation
          ? action.payload.filterRelation
          : null,
        filterConditionsTranslations: action.payload
          .filterConditionsTranslations
          ? action.payload.filterConditionsTranslations
          : null,
      };
    default:
      return state;
  }
}

// prepare user screen table date functions
const dateFormat = "DD MMM YY HH:mm:ss";

const prepareUserScreenTableDate = (tableData) => {
  if (tableData.length === 0) return; // safe return if tableData is empty

  const labels = [
    {
      name: "User Id",
      has_sort: false,
      type: STRING,
      selected: true,
      varName: "userId",
      defaultValue: "N/A",
    },
    {
      name: "Name",
      has_sort: false,
      type: STRING,
      selected: true,
      varName: "name",
      defaultValue: "N/A",
    },
    {
      name: "Mobile Number",
      has_sort: false,
      type: STRING,
      selected: true,
      varName: "mobileNum",
      defaultValue: "N/A",
    },
    {
      name: "Email",
      has_sort: false,
      type: STRING,
      selected: false,
      varName: "email",
      defaultValue: "N/A",
    },
    {
      name: "last_order_at",
      has_sort: false,
      type: DATE_TYPE,
      selected: false,
      varName: "last_order_at",
      defaultValue: "N/A",
    },
    {
      name: "phone",
      has_sort: false,
      type: STRING,
      selected: false,
      varName: "phone",
      defaultValue: "N/A",
    },
    {
      name: "SESSIONS",
      has_sort: false,
      type: STRING,
      selected: false,
      varName: "session",
      defaultValue: "N/A",
    },
    {
      name: "LAST SEEN AT",
      has_sort: true,
      type: DATE_TYPE,
      selected: true,
      varName: "updatedAt",
      defaultValue: "N/A",
    },
    {
      name: "Created At",
      has_sort: true,
      type: DATE_TYPE,
      selected: true,
      varName: "createdAt",
      defaultValue: "N/A",
    },
    {
      name: "house account balance",
      has_sort: true,
      type: NUMBER,
      selected: false,
      varName: "house_account_balance",
      defaultValue: "N/A",
    },
    {
      name: "house account limit",
      has_sort: true,
      type: STRING,
      selected: false,
      varName: "house_account_limit",
      defaultValue: "N/A",
    },
    {
      name: "subscription Channel",
      has_sort: true,
      type: STRING,
      selected: false,
      varName: "subscriptionChannel",
      defaultValue: "N/A",
    },
    {
      name: "is loyalty enabled",
      has_sort: true,
      type: NUMBER,
      selected: false,
      varName: "is_loyalty_enabled",
      defaultValue: "N/A",
    },
    {
      name: "orders",
      has_sort: true,
      type: NUMBER,
      selected: false,
      varName: "orders",
      defaultValue: "N/A",
    },
    {
      name: "is_house_account_enabled",
      has_sort: true,
      type: NUMBER,
      selected: false,
      varName: "is_house_account_enabled",
      defaultValue: "N/A",
    },
    {
      name: "is blacklisted ? ",
      has_sort: true,
      type: STRING,
      selected: true,
      varName: "is_blacklisted9",
      defaultValue: "N/A",
    },
    {
      name: "LAST ORDER VALUE",
      has_sort: true,
      type: NUMBER,
      selected: true,
      varName: "lastOrderval",
      defaultValue: "N/A",
    },
    {
      name: "TOTAL ORDERS VALUE",
      has_sort: true,
      type: NUMBER,
      selected: true,
      varName: "ltv",
      defaultValue: "N/A",
    },
    {
      name: "password",
      has_sort: true,
      type: STRING,
      selected: false,
      varName: "password",
      defaultValue: "N/A",
    },
    {
      name: "username",
      has_sort: true,
      type: STRING,
      selected: false,
      varName: "username",
      defaultValue: "N/A",
    },
  ];

  const rows = tableData.map((row) => {
    return {
      checked: false,
      actionsMenu: false,
      id: row.userId,
      values: labels.map((label) => {
        if (label.type === DATE_TYPE) {
          return moment(
            moment.utc(_.get(row, label.varName, label.defaultValue))
          )
            .local()
            .format(dateFormat);
        } else if (label.type === OBJECT) {
          return JSON.stringify(_.get(row, label.varName, label.defaultValue));
        } else if (label.type === NUMBER) {
          return formatNumber(_.get(row, label.varName, label.defaultValue));
        }
        return _.get(row, label.varName, label.defaultValue);
      }),
    };
  });

  return { labels, rows, actions: true };
};

const formatNumber = (num) => {
  return Intl.NumberFormat("en-US", {
    maximumFractionDigits: 2,
  }).format(num);
};
